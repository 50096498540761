import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>Sobre nós | Discover VitaMove</title>
        <meta
          name={"description"}
          content={"Onde cada viagem começa com força"}
        />
        <meta property={"og:title"} content={"Sobre nós | Discover VitaMove"} />
        <meta
          property={"og:description"}
          content={"Onde cada viagem começa com força"}
        />
        <meta
          property={"og:image"}
          content={"https://yovirex.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://yovirex.com/img/345772.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://yovirex.com/img/345772.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://yovirex.com/img/345772.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://yovirex.com/img/345772.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://yovirex.com/img/345772.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://yovirex.com/img/345772.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="140px 0"
        sm-padding="40px 0 40px 0"
        background="url(https://yovirex.com/img/3.jpg) center/cover"
      >
        <Override slot="SectionContent" sm-align-items="center" />
        <Box
          max-width="660px"
          padding="50px 80px 80px 50px"
          background="--color-light"
          color="--dark"
        >
          <Text
            as="h1"
            font="--base"
            color="--grey"
            letter-spacing="1px"
            text-transform="uppercase"
            margin="6px 0"
          >
            Sobre nós
          </Text>
          <Text as="h2" font="--headline2" margin="0 0 12px 0">
            A nossa história revela-se
          </Text>
          <Text font="--base">
            Descubra a essência do VitaMove, um santuário criado de raiz com uma
            visão singular: inspirar, motivar e cultivar uma comunidade dedicada
            à saúde e à boa forma física. Desde o nosso humilde início,
            esforçámo-nos por criar um espaço onde cada membro pode descobrir a
            sua força, ultrapassar os seus limites e alcançar o que antes
            parecia inalcançável. Os nossos alicerces assentam na crença de que
            o fitness transcende as fronteiras físicas, alimentando a mente e o
            espírito.
          </Text>
        </Box>
      </Section>
      <Section
        padding="120px 0 140px 0"
        sm-padding="80px 0 90px 0"
        quarkly-title="Statistics-4"
      >
        <Text
          margin="0px 0px 0px 0px"
          font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
        >
          Porque é que nos destacamos
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          margin="50px 0px 0px 0px"
          md-flex-direction="column"
          sm-margin="30px 0px 0px 0px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="60%"
            margin="0px 10% 0px 0px"
            padding="4px 0px 0px 0px"
            md-width="100%"
            md-margin="0px 10% 50px 0px"
            sm-padding="0 0px 0px 0px"
            sm-margin="0px 10% 35px 0px"
          >
            <Text
              margin="0px 0px 25px 0px"
              font="normal 300 25px/1.5 --fontFamily-sansHelvetica"
              color="#35363a"
            >
              Programas inovadores: Desde aulas de fitness de vanguarda a
              sessões de treino personalizadas, a inovação impulsiona as nossas
              ofertas.
            </Text>
            <Text
              margin="0px 0px 25px 0px"
              font="normal 300 25px/1.5 --fontFamily-sansHelvetica"
              color="#35363a"
            >
              Eventos da comunidade: Eventos regulares que juntam os membros,
              criando laços que vão para além do ginásio.
            </Text>
            <Text
              margin="0px 0px 25px 0px"
              font="normal 300 25px/1.5 --fontFamily-sansHelvetica"
              color="#35363a"
            >
              Bem-estar holístico: Adoptamos uma abordagem holística, oferecendo
              workshops sobre nutrição, atenção plena e bem-estar geral.
            </Text>
            <Text
              margin="0px 0px 25px 0px"
              font="normal 300 25px/1.5 --fontFamily-sansHelvetica"
              color="#35363a"
            >
              Espaço amigo do ambiente: A sustentabilidade é fundamental para o
              nosso projeto, com materiais e práticas ecológicas no centro das
              nossas operações.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-1"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          align-items="flex-start"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          justify-content="center"
          lg-width="100%"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
        >
          <Box
            width="100%"
            position="relative"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            padding="0px 0px 100% 0px"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            transition="transform 0.2s ease-in-out 0s"
          >
            <Image
              src="https://yovirex.com/img/4.jpg"
              position="absolute"
              display="block"
              width="100%"
              left={0}
              min-height="100%"
              object-fit="cover"
              top={0}
              right={0}
              bottom={0}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          width="50%"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          lg-order="1"
          flex-direction="column"
          lg-width="100%"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            width="100%"
            height="auto"
            overflow-x="hidden"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
          >
            <Image
              bottom={0}
              src="https://yovirex.com/img/5.jpg"
              top={0}
              display="block"
              width="100%"
              left={0}
              right={0}
              min-height="100%"
              object-fit="cover"
              position="absolute"
            />
          </Box>
          <Text
            margin="20px 0px 0px 0px"
            font="normal 700 32px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
          >
            Conheça os nossos treinadores
          </Text>
          <Text
            margin="24px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            No coração da VitaMove estão os nossos formadores - uma equipa de
            profissionais dedicados cuja paixão pela boa forma física só é
            igualada pelo seu empenho no seu sucesso. Com diferentes formações,
            os nossos instrutores trazem uma riqueza de conhecimentos, técnicas
            únicas e estratégias motivacionais para garantir que a sua viagem
            não é apenas eficaz, mas também agradável. Cada membro da nossa
            equipa está aqui para o orientar, apoiar e inspirar, fazendo com que
            cada sessão na VitaMove seja um passo em direção ao seu melhor.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
